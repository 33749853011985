import Vue from 'vue'
import store from '@/store'
import breakpoints from '@/assets/scss/export_vars.module.scss'

Vue.mixin({
  data: () => ({
    menuItems: [
      {
        name: 'Home',
        hash: '#home'
      },
      {
        name: 'Validation',
        hash: '#validation'
      },
      {
        name: 'Coins',
        hash: '#coins'
      },
      {
        name: 'Why stake',
        hash: '#why-stake'
      },
      {
        name: 'About us',
        hash: '#about-us'
      }
    ],
    windowWidth: 0
  }),
  filters: {
    formatNumber: value => {
      if (value === '' || !value) return '0.00'
      return value.toLocaleString('en-US')
    }
  },
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    isMobile () {
      return this.windowWidth <= parseInt(breakpoints.sm)
    },
    state () {
			return store.getters
		},
  }
})
