import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './routes/home'
import CoinPage from './routes/coin-page'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: () => import('@/components/main-container/MainContainer'),
			children: [
				Home,
				CoinPage
			]
		}
	]
})

router.beforeEach((to, from, next) => {
	if (to.hash) {
		const el = document.getElementById(to.hash.replace('#', ''))
		if (el) {
			window.scrollTo({
				top: el.offsetTop - 120,
				behavior: 'smooth'
			})
		}
	} else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

	if (!to.meta.middleware) {
		return next()
	}

	const middleware = to.meta.middleware

	if (middleware.length === 0) {
		return next()
	}
	const context = {
		to,
		from,
		next,
		// store
	}
	for (var key in middleware) {
		middleware[key]({
			...context
		})
	}
})



export default router
