import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/scss/app.scss'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

import './plugins/axios'

require('@/plugins/vueMixin')

Vue.config.productionTip = false

new Vue({
	router,
  store,
  render: h => h(App),
}).$mount('#app')
