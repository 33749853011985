export default {
	state: {
    currentCoin: '',
		coins: [
      {
        id: 1,
        iconName: 'casper.png',
        name: 'Casper',
        abbr: 'cspr',
        percent: '12%',
        api_id: 'casper-network',
        desc: 'The Casper Network is the first live proof-of-stake blockchain built off the Casper CBC specification. Casper is designed to accelerate enterprise and developer adoption of blockchain technology today and evolve to meet user needs in the future.',
        info: [
          {
            title: 'Rewards',
            text: 'Distributed once per era (~ 2 hours)'
          },
          {
            title: 'Slashing',
            text: '~90 days of jailtime for validators double-signing'
          },
          {
            title: 'Inflation',
            text: '8% annually'
          },
          {
            title: 'Unbonding',
            text: '14 hours'
          },
          {
            title: 'Compounding',
            text: 'Compounded automatically'
          }
        ],
        validatorAddresses: [
          {
            address: '01823a64b34005d89e621404d1aae59d8c0e592e9cb2757fe98c85f56b785e5e5a',
            link: 'https://monitoring.stakernode.com:2053/d/rYdddlPWk0/casper?orgId=1&refresh=1m&from=now-7d&to=now&kiosk=tv'
          }
        ],
        guideTitle: 'The easiest way to stake Casper',
        stakeLink: 'https://cspr.live/validator/01823a64b34005d89e621404d1aae59d8c0e592e9cb2757fe98c85f56b785e5e5a',
        stakeGuide: [
          {
            text: 'Follow the link <a href="https://cspr.live/validator/01823a64b34005d89e621404d1aae59d8c0e592e9cb2757fe98c85f56b785e5e5a" target="_blank">https://cspr.live/validator/01823a64b34005d89e621404d1aae59d8c0e592e9cb2757fe98c85f56b785e5e5a</a>',
            step: 1
          },
          {
            text: 'Enter the amount you want to delegate. Remember that you need to keep a certain amount of coins to pay the commission.',
            step: 4
          },
          {
            text: 'Click "Delegate Stake"',
            step: 2
          },
          {
            text: 'Click “delegate” and then click “approve” to approve the transaction.',
            step: 5
          },
          {
            text: 'Sign in with a compatible wallet like Signer or Ledger',
            step: 3
          }
        ],
        faq: [
          {
            id: 1,
            active: true,
            title: 'Where can I explore the network and create a Casper wallet?',
            details: 'Casper is counting on the community to help build a website and wallet that can be accessible to all.'
          },
          {
            id: 2,
            active: false,
            title: 'What is the name of the asset being staked?',
            details: 'Caspers’ native token, CSPR, is used to pay computation fees and reward validators.'
          },
          {
            id: 3,
            active: false,
            title: 'When are staking rewards enabled? When are transfers enabled?',
            details: 'Staking rewards are enabled at mainnet launch. Transfers are enabled, but holders cannot trade tokens until they become unlocked.'
          },
          {
            id: 4,
            active: false,
            title: 'Why stake CSPR?',
            details: 'The CSPR token also gives stakers the right to vote on policy decisions for how the Casper will operate and distribute treasury funds. <br> <br> Stakers will also capture fees from network transactions, so as Casper transaction volume increases, CSPR stakers will earn more than new issuance subsidies.'
          },
          {
            id: 5,
            active: false,
            title: 'How long does it take to stake & unstake CSPR?',
            details: '7 days. During this time neither validators nor delegators receive rewards.'
          },
          {
            id: 6,
            active: false,
            title: 'Do I maintain custody of my CSPR tokens? Who or what controls my staked CSPR token?',
            details: 'The Casper protocol takes control of your CSPR tokens while you are staking. If you unbond your tokens, this process will take 1 day before the protocol returns your tokens to you.'
          },
          {
            id: 7,
            active: false,
            title: 'Can my staked CSPR be slashed (seized or destroyed)?',
            details: 'Casper does not slash for downtime. <br><br>They have introduced slashing for equivocation, or double-signing. For ~90 days a validator’s stake will be unbondable and untransferable.'
          },
          {
            id: 8,
            active: false,
            title: 'How is staking income disbursed? Is staking income liquid or automatically staked?',
            details: 'Staking income on Casper is automatically distributed once per era (~ 2 hours). <br><br> Rewards will be automatically staked. Rewards are locked for the first 90 days, along with all other tokens.'
          },
          {
            id: 9,
            active: false,
            title: 'Can I lose potential staking rewards?',
            details: 'If a node is offline for an entire era the node will be removed from the validator set and will not earn rewards. <br><br> A StakerNode employee with extensive experience in node support avoids such situations.'
          }
        ],
        features: [
          {
            title: 'Experienced & Trusted',
            text: 'The server is maintained by a DevOps team with many years of experience supporting and developing various IT projects. <br> Support for crypto projects is one of the priority areas of our company.'
          },
          {
            title: 'Features & Benefits',
            text: 'Our Commission rate is 4% <br> Active participant in the Casper ecosystem. Constant monitoring and installation of updates. <br> Personal chat support for nominees.'
          },
          {
            title: 'Security & Safety',
            text: 'Our staking services are trusted by millions of dollars from both institutional and retail investors. <br> We support delegators by resolving staking-related questions and complications on Telegram (https://t.me/StakerNodesSupport) or via Email (info@stakernode.com).'
          }
        ]
      },
      {
        id: 2,
        iconName: 'xx.png',
        name: 'XX.network',
        abbr: 'xx',
        percent: '19%',
        api_id: 'xxcoin',
        stakeLink: 'https://dashboard.xx.network/nodes/S-kmJ6xRsv9qx5S7qDuzXpA-KH_gTTssYYyEmqxvpaIC',
        desc: 'xx network combines the quantum secure, high performance digital currency and consensus protocol of xx consensus with the privacy-protecting, high speed mix technology of xx cMix.  The result is the first efficient online platform that allows you to quickly and securely communicate and exchange value on your smartphone without revealing your metadata. While many messaging platforms are encrypted, no others hide metadata - information about your activity - from organizations that collect and sell data.',
        info: [
          {
            title: 'Rewards',
            text: 'Distributed once per era (~ 24 hours)'
          },
          {
            title: 'Slashing',
            text: 'No'
          },
          {
            title: 'Inflation',
            text: 'Coming soon'
          },
          {
            title: 'Unbonding',
            text: '0-24 Hours'
          },
          {
            title: 'Compounding',
            text: 'Compounded automatically'
          }
        ],
        validatorAddresses: [
          {
            address: '6VN6S9A8AXLTp5HZLEuZMtmZjWMhHM56uiaG4u7A7itQWgqN',
            link: 'https://monitoring.stakernode.com:2053/d/xx/xx-network?orgId=1&refresh=1m&var-DS_PROMETHEUS=default&var-job=node&var-node=XX0%3A2052&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B&from=now-7d&to=now'
          }
        ],
        guideTitle: 'The easiest way to stake XX.network',
        stakeGuide: [
          {
            text: 'Follow the link <a href="https://explorer.xx.network/#/staking" target="_blank">https://explorer.xx.network/#/staking</a>',
            step: 1
          },
          {
            text: 'In the window that opens, follow the instructions.',
            step: 4
          },
          {
            text: 'Using search find the STAKERNODE.COM validator or input  these address 6VN6S9A8AXLTp5HZLEuZMtmZjWMhHM56uiaG4u7A7itQWgqN',
            step: 2
          },
          {
            text: 'After selecting the validator in the window that opens, click the "Send" button.',
            step: 3
          }
        ],
        faq: [
          {
            id: 1,
            active: true,
            title: 'How are Nodes Rewarded for Running the Network?',
            details: 'Nodes receive a share of the reward generated for each block.  Assuming that each node performs well in every role it plays, then, over time, the network will allocate the same amount of block rewards to each node because of the random selection of nodes (to participate in mixing, endorser and block producer teams).<br><br> However, if nodes do not perform well, they will be penalized and lose some or all of the block reward that they normally would earn.'
          },
          {
            id: 2,
            active: false,
            title: 'Where is xx network based and who developed it?',
            details: 'The xx network team and company (xx labs SEZC) is based and headquartered in George Town, Cayman Islands. xx network uses the xx coin digital currency and consensus technology developed in Cayman and the xx cMix privacy layer developed in Los Angeles. <br> Both projects are led by Dr. David Chaum. Chaum is one of the earliest blockchain researchers and a world-renowned cryptographer and privacy advocate. xx network was founded by Chaum and is led by William Carter, a computer scientist and systems design expert.  The xx cMix team is led by Benjamin Wenger. The xx network teams are made up of cryptographers, developers, and marketers all working together to create the most powerful blockchain network for communicating and sharing value anywhere in the world.'
          },
          {
            id: 3,
            active: false,
            title: 'What are Elixxir and Praxxis?',
            details: 'Elixxir was the original project name for xx cMix and Praxxis was the original project name for xx consensus. The projects were originally developed in stealth and were merged to form the xx network.'
          },
          {
            id: 4,
            active: false,
            title: 'What is xx messenger?',
            details: `xx messenger supports messaging that's actually private. Existing privacy messengers encrypt your messages, but they reveal who you are talking to and when you’re talking. Open source xx cMix technology breaks the link between sender and receiver, fully eliminating your digital footprint. And messages stay end-to-end encrypted.`
          },
          {
            id: 5,
            active: false,
            title: 'Is xx network free to use?',
            details: 'xx messenger is free for consumers. Those running decentralized applications, smart contracts, and businesses on xx network will be able to use xx coins to purchase higher bandwidth.'
          },
          {
            id: 6,
            active: false,
            title: 'What is xx coin?',
            details: 'xx coin is a secure digital currency that can be traded among users and businesses while incentivizing operations on the xx network. xx coin is used to incentivize governance that enforces transparent operation of the network and empowers users to participate in the ecosystem. xx coin is also used to pay for high volume usage, effect payments and for dApp incentivization.'
          }
        ],
        features: [
          {
            title: 'Experienced & Trusted',
            text: 'The server is maintained by a DevOps team with many years of experience supporting and developing various IT projects. <br> Support for crypto projects is one of the priority areas of our company.'
          },
          {
            title: 'Features & Benefits',
            text: 'Our Commission rate is max 2% (depends on the node) <br> Active participant in the HydraDX ecosystem. Constant monitoring and installation of updates. <br> Personal chat support for nominees.'
          },
          {
            title: 'Security & Safety',
            text: 'Our staking services are trusted by millions of dollars from both institutional and retail investors. <br> We support delegators by resolving staking-related questions and complications on Telegram (https://t.me/StakerNodesSupport) or via Email (info@stakernode.com).'
          }
        ]
      },
      {
        id: 3,
        iconName: 'somm_new.webp',
        name: 'Sommelier',
        abbr: 'SOMM',
        coinSlug: 'Sommelier',
        percent: '24%',
        api_id: 'sommelier',
        stakeLink: 'https://wallet.keplr.app/chains/sommelier?modal=validator&chain=sommelier-3&validator_address=sommvaloper1dr9295zl7v4z3hl9rszv0v552wrjw7lpxh0n4m',
        desc: 'Sommelier is built on the Cosmos SDK to maximize interoperability with other blockchains, and the platform is a reimagined gateway into interchain DeFi. With a bridge in place connecting the Cosmos-based platform to Ethereum, Sommelier will be able to execute strategies across multiple blockchains, opening a wide horizon of possibilities.',
        info: [
          {
            title: 'Rewards',
            text: 'Each block'
          },
          {
            title: 'Slashing',
            text: 'No'
          },
          {
            title: 'Unbonding',
            text: '21 days'
          },
          {
            title: 'Compounding',
            text: 'Yes'
          }
        ],
        validatorAddresses: [
          {
            address: 'sommvaloper1dr9295zl7v4z3hl9rszv0v552wrjw7lpxh0n4m'
          }
        ],
        guideTitle: 'The easiest way to stake Sommelier',
        stakeGuide: [
          {
            text: `Go to the <a href="https://wallet.keplr.app/chains/sommelier" target="_blank">https://wallet.keplr.app/chains/sommelier</a>, and connect the keplr wallet`,
            step: 1
          },
          {
            text: 'Choose from the list of validators you trust, we recommend Stakernode',
            step: 2
          },
          {
            text: 'In the window that opens, follow the instructions.',
            step: 3
          }
        ],
        faq: [
          {
            id: 1,
            active: true,
            title: 'How do I know that particular strategy will work?',
            height: '165',
            details: `
              Before submitting the strategy for the validators, each strategy's performance is backtested to prove its potential. Backtesting results tell us how the automated strategy would have performed based on historical data. The automated strategy, on the other hand, is an iterative process. We can use our simulators to refine the strategy to yield the best results on historical data.
              <br><br>
              Sommelier also deposits our own funds to prove the validity of each strategy and kick-off the initial liquidity so users could see strategy's immediate performance.
            `
          },
          {
            id: 2,
            active: false,
            title: 'How is the strategy prepared?',
            height: '195',
            details: `
              Scenario #1:
              <ul class="list-1">
              <li>Strategy providers - Initial idea how strategy could work</li>
              <li>Sommelier - Helps put the strategy in a mathematical model</li>
              <li>Sommelier - Sommelier data team helps backtest the strategy performance.</li>
              </ul>
              Scenario #2:
              <ul class="list-1">
              <li>Strategy providers - Have already defined strategy model and generated backtested strategy performance.</li>
              <li>The Sommelier cellar team will help strategy get live on the platform where it can allow interested parties to deposit in the cellars attractive to them.</li>
              </ul>
            `
          },
          {
            id: 3,
            active: false,
            title: 'Will strategy backtesting be automated?',
            height: '95',
            details: 'In an ideal world, a strategist could run a strategy on Sommelier with minimal (if any) programming experience. Even with programming experience, building backtesting infrastructure is non-trivial. To lower this barrier, we are working on developing generic and flexible tools to support backtesting and deploying new Cellar strategies on Sommelier.'
          },
          {
            id: 4,
            active: false,
            title: 'Who approves strategy results?',
            height: '100',
            details: `
              Strategy backtesting results are submitted for validators.
              Validators hold the voting power to approve the strategy.
              If the validators accept the strategy, Steward (endpoint which later will take in the strategy signals and distribute them across validators) can start accepting the signals from the strategy.
            `
          },
        ],
        features: [
          {
            title: 'Experienced & Trusted',
            text: 'The server is maintained by a DevOps team with many years of experience supporting and developing various IT projects. <br> Support for crypto projects is one of the priority areas of our company.'
          },
          {
            title: 'Features & Benefits',
            text: 'Our Commission rate is max 2% (depends on the node) <br> Active participant in the HydraDX ecosystem. Constant monitoring and installation of updates. <br> Personal chat support for nominees.'
          },
          {
            title: 'Security & Safety',
            text: 'Our staking services are trusted by millions of dollars from both institutional and retail investors. <br> We support delegators by resolving staking-related questions and complications on Telegram (<a href="https://t.me/StakerNodesSupport" target="_blank">https://t.me/StakerNodesSupport</a>) or via Email (<a href="mailto:info@stakernode.com">info@stakernode.com</a>).'
          }
        ]
      },
      {
        id: 4,
        iconName: 'strd.webp',
        name: 'Stride',
        abbr: 'STRD',
        coinSlug: 'Stride',
        percent: '5%',
        api_id: 'stride',
        stakeLink: 'https://wallet.keplr.app/chains/stride?modal=validator&chain=stride-1&validator_address=stridevaloper1h4ya59vgv7f78755saqts4lq2nky8s0tndvgv8',
        desc: `
          Stride is a blockchain ("zone") that provides liquidity for staked assets. Using Stride, you can earn both staking and DeFi yields across the Cosmos IBC ecosystem.
        `,
        info: [
          {
            title: 'Rewards',
            text: 'Each block'
          },
          {
            title: 'Slashing',
            text: 'No'
          },
          {
            title: 'Unbonding',
            text: '21 days'
          },
          {
            title: 'Compounding',
            text: 'Yes'
          }
        ],
        validatorAddresses: [
          {
            address: 'stridevaloper1h4ya59vgv7f78755saqts4lq2nky8s0tndvgv8'
          }
        ],
        guideTitle: 'The easiest way to stake Stride',
        stakeGuide: [
          {
            text: `Go to the <a href="https://wallet.keplr.app/chains/stride" target="_blank">https://wallet.keplr.app/chains/stride</a>, and connect the keplr wallet`,
            step: 1
          },
          {
            text: 'Choose from the list of validators you trust, we recommend Stakernode',
            step: 2
          },
          {
            text: 'In the window that opens, follow the instructions.',
            step: 3
          }
        ],
        faq: [
          {
            id: 1,
            active: true,
            title: 'Is Stride audited?',
            height: '90',
            details: `
              Not once, but twice! <br>
              At Stride, security is our biggest focus. That's why we engaged two auditing firms to conduct two separate audits of the Stride code.
              <br>
              Stride has been fully audited by <a href="https://www.oaksecurity.io/" target="_blank">Oak Security</a> and <a href="https://www.certik.com/" target="_blank">Certik</a>.
            `
          },
          {
            id: 2,
            active: false,
            title: 'What tokens are currently supported?',
            height: '45',
            details: `
              ATOM, JUNO, and STARS are supported (as of October 7th).
            `
          },
          {
            id: 3,
            active: false,
            title: 'What tokens are expected to be supported in the future?',
            height: '235',
            details: `
              OSMO will be supported in the next few weeks.
              <br>
              Any Cosmos chain using IBC V3 with interchain accounts can be on-boarded via a governance vote. Here's a list of additional candidates, which may be supported by end of the year.
              <ul class="list-1">
              <li>Secret Network (stSCRT)</li>
              <li>Injective (stINJ)</li>
              <li>Evmos (stEVMOS)</li>
              <li>Terra V2 (stLUNA)</li>
              <li>Sei Network (stSEI)</li>
              <li>Kujira (stKUJI)</li>
              </ul>
            `
          },
          {
            id: 4,
            active: false,
            title: 'What’s the benefit of liquid staking?',
            details: `
              With liquid staking, users no longer have to choose between staking their proof-of-stake (PoS) tokens and using their tokens. Traditionally, users could either stake their tokens and earn staking rewards, or they could forego staking rewards in order to keep their tokens liquid. But with liquid staking users can do both: earn staking rewards without giving up the ability for tokens to be sold, transferred, or used in DeFi.
            `
          },
        ],
        features: [
          {
            title: 'Experienced & Trusted',
            text: 'The server is maintained by a DevOps team with many years of experience supporting and developing various IT projects. <br> Support for crypto projects is one of the priority areas of our company.'
          },
          {
            title: 'Features & Benefits',
            text: 'Our Commission rate is max 2% (depends on the node) <br> Active participant in the HydraDX ecosystem. Constant monitoring and installation of updates. <br> Personal chat support for nominees.'
          },
          {
            title: 'Security & Safety',
            text: 'Our staking services are trusted by millions of dollars from both institutional and retail investors. <br> We support delegators by resolving staking-related questions and complications on Telegram (<a href="https://t.me/StakerNodesSupport" target="_blank">https://t.me/StakerNodesSupport</a>) or via Email (<a href="mailto:info@stakernode.com">info@stakernode.com</a>).'
          }
        ]
      },
      {
        id: 5,
        iconName: 'darc.webp',
        name: 'Konstellation',
        abbr: 'DARC',
        coinSlug: 'konstellation',
        percent: '35%',
        api_id: 'darcmatter-coin',
        stakeLink: 'https://restake.app/konstellation/darcvaloper1czn57jr7j00z5hh63f98mg8l8zrwur7ycta083/delegate',
        desc: 'Konstellation Network will create the backbone for the seamless bridge between capital and returns through: interoperable cross-chain capital markets solutions, highly composable & interoperable DeFi, and optimal connectivity between capital & returns.',
        info: [
          {
            title: 'Rewards',
            text: 'Each block'
          },
          {
            title: 'Slashing',
            text: 'No'
          },
          {
            title: 'APR',
            text: '35%'
          },
          {
            title: 'Inflation',
            text: '18%'
          },
          {
            title: 'Unbonding',
            text: '21 days'
          },
          {
            title: 'Compounding',
            text: 'Yes'
          }
        ],
        validatorAddresses: [
          {
            address: 'darcvaloper1czn57jr7j00z5hh63f98mg8l8zrwur7ycta083'
          }
        ],
        guideTitle: 'The easiest way to stake Konstellation',
        stakeGuide: [
          {
            text: `Go to the <a href="https://restake.app/konstellation" target="_blank">https://restake.app/konstellation</a>, and connect <br> the keplr wallet`,
            step: 1
          },
          {
            text: 'Choose from the list of validators you trust, we recommend Stakernode',
            step: 2
          },
          {
            text: 'In the window that opens, follow the instructions.',
            step: 3
          }
        ],
        faq: [
          {
            id: 1,
            active: true,
            title: 'What is Konstellation?',
            details: 'Konstellation Network is a blockchain protocol, built on Cosmos SDK, creating a global infrastructure for the future of the decentralized capital markets.'
          },
          {
            id: 2,
            active: false,
            title: 'What is Goals?',
            details: 'Konstellation Network will create the backbone for the seamless bridge between capital and returns through: interoperable cross-chain capital markets solutions, highly composable & interoperable DeFi, and optimal connectivity between capital & returns.'
          },
          {
            id: 3,
            active: false,
            title: 'What is Alkemy?',
            details: 'Create, trade and earn with any global currency stablecoin created by Alkemy. Alkemy is the first omni-currency stablecoin platform that utilizes a native hybrid algorithmic system to implement a single reserve based on both endogenous and exogenous collateral.'
          },
          {
            id: 4,
            active: false,
            title: 'What is DARC?',
            details: `The DARC token facilitates all transactions, staking, and decision-making activities to support the Konstellation ecosystem.`
          },
        ],
        features: [
          {
            title: 'Experienced & Trusted',
            text: 'The server is maintained by a DevOps team with many years of experience supporting and developing various IT projects. <br> Support for crypto projects is one of the priority areas of our company.'
          },
          {
            title: 'Features & Benefits',
            text: 'Our Commission rate is max 2% (depends on the node) <br> Active participant in the HydraDX ecosystem. Constant monitoring and installation of updates. <br> Personal chat support for nominees.'
          },
          {
            title: 'Security & Safety',
            text: 'Our staking services are trusted by millions of dollars from both institutional and retail investors. <br> We support delegators by resolving staking-related questions and complications on Telegram (<a href="https://t.me/StakerNodesSupport" target="_blank">https://t.me/StakerNodesSupport</a>) or via Email (<a href="mailto:info@stakernode.com">info@stakernode.com</a>).'
          }
        ]
      },
      {
        id: 6,
        iconName: 'iris.webp',
        name: 'IRIS Network',
        abbr: 'IRIS',
        coinSlug: 'iris',
        percent: '10%',
        api_id: 'iris-network',
        stakeLink: 'https://wallet.keplr.app/chains/irisnet?modal=validator&chain=irishub-1&validator_address=iva1hmfjyaygehcpc8dvz6g3g9m4d33fa3ge5wyggz',
        desc: '<p>IRISnet is designed to be the foundation for the next generation of distributed applications. Built with Cosmos-SDK, IRIS Hub enables cross-chain interoperability through a unified service model, while providing a variety of modules to support DeFi applications.</p><br><p>IRISnet dev teams include Bianjie, a national award-winning blockchain technology team based in Shanghai, and Tendermint, the world-famous team that created the Tendermint consensus engine and the Cosmos project.</p>',
        info: [
          {
            title: 'Rewards',
            text: 'Each block'
          },
          {
            title: 'Slashing',
            text: 'No'
          },
          {
            title: 'Unbonding',
            text: '21 days'
          },
          {
            title: 'Compounding',
            text: 'No'
          }
        ],
        validatorAddresses: [
          {
            address: 'iva1hmfjyaygehcpc8dvz6g3g9m4d33fa3ge5wyggz'
          }
        ],
        guideTitle: 'The easiest way to stake IRIS Network',
        stakeGuide: [
          {
            text: `Go to the <a href="https://wallet.keplr.app/chains/irisnet" target="_blank">https://wallet.keplr.app/chains/irisnet</a>, and connect the keplr wallet`,
            step: 1
          },
          {
            text: 'Choose from the list of validators you trust, we recommend Stakernode',
            step: 2
          },
          {
            text: 'In the window that opens, follow the instructions.',
            step: 3
          }
        ],
        faq: [
          {
            id: 1,
            active: true,
            title: 'What is Iris Hub?',
            details: 'At the "center" of the IRIS network is a blockchain known as the IRIS Hub, which is a Proof-of-Stake (PoS) blockchain built on Cosmos SDK and Tendermint. It will be the first regional hub that connects to the Cosmos Hub as one of its zones. The IRIS hub is equipped with a service protocol that coordinates on-chain transaction processing with off-chain data processing and business logic execution. We have also enhanced the IBC protocol to facilitate cross-chain invocation of those off-chain services, if so desired.'
          },
          {
            id: 2,
            active: false,
            title: 'What is the purpose of the IRIS token?',
            details: `
            <p>The IRIS hub has its own native token known as IRIS. It is designed to serve three purposes in the network.</p>
            <br>
            <ul class="list-1">
            <li class="d-block">Staking. Similar to the ATOM token in the Cosmos Hub, the IRIS token will be used as a staking token to secure the PoS blockchain.</li>
            <li class="d-block">Transaction Fee. The IRIS token will also be used to pay fees for all transactions in the IRIS network</li>
            <li class="d-block">Service Fee. It is required that service providers in the IRIS network charge service fees denominated in the IRIS token.</li>
            <ul/>`
          },
          {
            id: 3,
            active: false,
            title: 'Can a validator run away with their delegators\' funds?',
            details: 'By delegating to a validator, an user delegates voting power. The more voting power a validator has, the more weight they have in the consensus and governance processes. This does not mean that the validator has custody of their delegators\' funds. By no means can a validator run away with its delegator\'s funds.'
          },
          {
            id: 4,
            active: false,
            title: 'What are validators commission?',
            details: 'Revenue received by a validator\'s pool is split between the validator and their delegators. The validator can apply a commission on the part of the revenue that goes to their delegators. This commission is set as a percentage. Each validator is free to set their initial commission, maximum daily commission change rate and maximum commission. The IRIS Hub enforces the parameter that each validator sets. Only the commission rate can change after the validator is created.'
          },
          {
            id: 5,
            active: false,
            title: 'What is the inflation rate in Iris Network?',
            details: `
              <p>Block Inflation exists to incentivize IRIS holders to stake. The more staked IRIS tokens are, more secure the network become(Read more about Staking).</p>
              <br>
              <p>Block Inflation will be distributed every block. Inflation rate in IRIShub for the first year will be 4%. This ration could be adjusted by parameter-change proposals. In this way, loose IRIS will devalue year by year.</p>`
          },
        ],
        features: [
          {
            title: 'Experienced & Trusted',
            text: 'The server is maintained by a DevOps team with many years of experience supporting and developing various IT projects. <br> Support for crypto projects is one of the priority areas of our company.'
          },
          {
            title: 'Features & Benefits',
            text: 'Our Commission rate is max 2% (depends on the node) <br> Active participant in the HydraDX ecosystem. Constant monitoring and installation of updates. <br> Personal chat support for nominees.'
          },
          {
            title: 'Security & Safety',
            text: 'Our staking services are trusted by millions of dollars from both institutional and retail investors. <br> We support delegators by resolving staking-related questions and complications on Telegram (https://t.me/StakerNodesSupport) or via Email (info@stakernode.com).'
          }
        ]
      },
      {
        id: 7,
        iconName: 'solana.svg',
        name: 'Solana',
        abbr: 'sol',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://solana.foundation/sfdp-validators/CtFoYwkAMiPhmY31cCgzz61rjYRSsZiTpPpyED2MwqHe'
      },
      {
        id: 8,
        iconName: 'minima.jpeg',
        name: 'Minima',
        abbr: 'minima',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-minima-c2572111188a'
      },
      {
        id: 9,
        iconName: 'massa.png',
        name: 'Massa',
        abbr: 'massa',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%B7%D0%BD%D0%B0%D0%BA%D0%BE%D0%BC%D1%81%D1%82%D0%B2%D0%BE-%D1%81-massa-358452c99505'
      },
      {
        id: 10,
        iconName: 'meson.svg',
        name: 'Meson',
        abbr: 'meson',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%BA%D0%B0%D0%BA-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0%D0%B5%D1%82-meson-network-3b4573bc833d'
      },
      {
        id: 11,
        iconName: 'masa.svg',
        name: 'Masa',
        abbr: 'masa',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D1%8F%D0%B5%D0%BC-masa-fb57919d0e08'
      },
      {
        id: 12,
        iconName: 'starknet.svg',
        name: 'StarkNet',
        abbr: 'StarkNet',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%BD%D0%B0-%D0%BF%D1%83%D1%82%D0%B8-%D0%BA-starknet-zk-rollup-l2-%D0%BD%D0%B0-%D0%B1%D0%B0%D0%B7%D0%B5-stark-25bdfbd91514'
      },
      {
        id: 13,
        iconName: 'defund.png',
        backgroundColor: '#f7f7f7',
        name: 'Defund',
        abbr: 'Defund',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D1%8F%D0%B5%D0%BC-defund-c22bfd1c7aa7'
      },
      {
        id: 14,
        iconName: 'dws.png',
        name: 'DWS',
        abbr: 'DWS',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%B4%D0%BE%D1%80%D0%BE%D0%B6%D0%BD%D0%B0%D1%8F-%D0%BA%D0%B0%D1%80%D1%82%D0%B0-dws-%D0%B8-%D0%BE%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8-bb9823c9b446'
      },
      {
        id: 15,
        iconName: 'espresso.svg',
        name: 'Espresso',
        abbr: 'Espresso',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D1%8F%D0%B5%D0%BC-espresso-systems-8f5f4a054f08'
      },
      {
        id: 16,
        iconName: 'bundlr.svg',
        name: 'Bundlr',
        abbr: 'Bundlr',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%B4%D0%BE%D0%B1%D1%80%D0%BE-%D0%BF%D0%BE%D0%B6%D0%B0%D0%BB%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-%D0%B2-%D1%81%D0%B5%D1%82%D1%8C-bundlr-e9c0a69d3f51'
      },
      {
        id: 17,
        iconName: 'trans.svg',
        name: 'Transformers',
        abbr: 'tfsc',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5transformers-tfsc-d0ee57ea2229'
      },
      {
        id: 18,
        iconName: 'nulink.webp',
        name: 'Nulink',
        abbr: 'Nulink',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/%D0%B4%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE-%D1%81-%D0%BD%D1%83%D0%BB%D0%B5%D0%B2%D1%8B%D0%BC-%D1%80%D0%B0%D0%B7%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%D0%BC-%D0%B2-nulink-1395e05b29c5'
      },
      {
        id: 19,
        iconName: 'manta.svg',
        name: 'Manta',
        abbr: 'Manta',
        percent: '',
        isTest: true,
        moreInfoLink: 'https://medium.com/@stakernode/manta-%D0%BF%D0%B5%D1%80%D0%B2%D1%8B%D0%B9-%D1%81%D1%82%D0%B5%D0%BA-defi-%D1%81%D0%BE%D1%85%D1%80%D0%B0%D0%BD%D1%8F%D1%8E%D1%89%D0%B8%D0%B9-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C-%D0%BD%D0%B0-%D0%B1%D0%B0%D0%B7%D0%B5-zksnark-bc1208d2d01f'
      },
      {
        id: 20,
        iconName: 'aleo.png',
        name: 'Aleo',
        abbr: 'Aleo',
        percent: '',
        isTest: true
      }
    ],
    endedCoins: [
      {
        id: 1,
        iconName: 'hdx.jpeg',
        name: 'HydraDX',
        abbr: 'hdx',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 2,
        iconName: 'taraxa.png',
        name: 'Taraxa',
        abbr: 'tara',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 3,
        iconName: 'bit.country.png',
        name: 'Bit.country',
        abbr: '$nuum',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 4,
        iconName: 'nym.jpeg',
        name: 'Nym',
        abbr: 'nym',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 5,
        iconName: 'zeitgeist.png',
        name: 'Zeitgeist',
        abbr: 'zeitgeist',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 6,
        iconName: 'kyve.svg',
        name: 'Kyve',
        abbr: 'kyve',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 7,
        iconName: 'joy.png',
        name: 'Joystream',
        abbr: 'JOY',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 8,
        iconName: 'aptos.png',
        name: 'Aptos',
        abbr: 'Aptos',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 9,
        iconName: 'hdx.jpeg',
        name: 'HydraDX',
        abbr: 'hdx',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 10,
        iconName: 'iron.png',
        name: 'Ironfish',
        abbr: 'IRON',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 11,
        iconName: 'om.png',
        name: 'Omniflix',
        abbr: 'Omniflix',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 12,
        iconName: 'porta.png',
        name: 'Porta',
        abbr: 'KIAN',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 13,
        iconName: 'ev.png',
        name: 'EVMOS',
        abbr: 'EVMOS',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 14,
        iconName: 'moonbeam.png',
        name: 'Moonbeam',
        abbr: 'GLMR',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 15,
        iconName: 'mina.png',
        name: 'Mina',
        abbr: 'mina',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      },
      {
        id: 16,
        iconName: 'sifchain.png',
        name: 'Sifchain',
        abbr: 'erowan',
        coinSlug: 'sifchain',
        percent: '',
        isTest: true,
        isTestText: 'ended'
      }
    ]
	},
	getters: {
		coins: state => state.coins,
    endedCoins: state => state.endedCoins,
    selectedCoin: state => state.coins.find(item => item.abbr === state.currentCoin || item.coinSlug === state.currentCoin)
	},
  mutations: {
		SET_CURRENT_COIN (state, value) {
			state.currentCoin = value
		}
  },
  actions: {
    setCurrentCoin (context, value) {
      context.commit('SET_CURRENT_COIN', value)
    }
  }
}
