<template>
  <div id="app">
    <div v-if="isReconstruction" class="text-center mt-30">
      <span>
        Site under reconstruction
      </span>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    isReconstruction: false
  })
}
</script>

