import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/coin/:slug',
			name: 'CoinPage',
			component: () => import('@/pages/coin-page/CoinPage')
		}
	]
}
