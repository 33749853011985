import store from '@/store'

class ApiBase {
  static setToStore (self, name, data, callBack) {
    store.dispatch(name, data)
    if (callBack) {
      callBack()
    }
  }
}

export default ApiBase
